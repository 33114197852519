<template>
  <div
    id="init"
    v-loading="loading"
    element-loading-text="页面加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(173 ,216, 230, 0.8)"
  ></div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { FrameApi } from "@/plugins/api.js";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { isMobile, isWeiXin, getQueryString } from "@/utils/common.js";
import wx from "weixin-js-sdk";
const loading = ref(true);
const closeWin = (times = 2000) => {
  setTimeout(() => {
    window.opener = null;
    window.open("", "_self");
    window.location.href = "about:blank";
    window.close();

    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        window.WeixinJSBridge.call("closeWindow");
      },
      false
    );
    window.WeixinJSBridge.call("closeWindow");
  }, times);
};
const route = useRoute();
const router = useRouter();
const routeQuery = route.query;

function goTo() {
  if (isWeiXin()) {
    if (isMobile()) {
      ElMessage.error("请通过PC端企业微信内置浏览器打开");
      closeWin();
    } else {
      const params = {
        auth_path: route.fullPath,
      };
      FrameApi.qySdk(params).then((res) => {
        if (res.Code === 200) {
          wx.config({
            beta: true,
            debug: false,
            appId: res.Data.appId,
            timestamp: res.Data.timestamp,
            nonceStr: res.Data.nonceStr,
            signature: res.Data.signature,
            jsApiList: ["openDefaultBrowser"],
          });
          wx.ready(function () {
            wx.invoke(
              "openDefaultBrowser",
              {
                url: res.Data.auth_url,
              },
              function (res) {
                if (res.err_msg == "openDefaultBrowser:ok") {
                  ElMessage.error({
                    message: "打开默认浏览器成功，即将进行授权登录",
                    type: "success",
                  });
                } else {
                  ElMessage.error("打开默认浏览器失败，请退出后重试");
                }

                closeWin();
              }
            );
          });
        } else {
          let msg = res.Message ? res.Message : "一键登录失败，请重试！！";
          ElMessage.error(msg);
          closeWin();
        }
      });
    }
  } else {
    const code = routeQuery.code ? routeQuery.code : getQueryString("code");
    const state = routeQuery.state ? routeQuery.state : getQueryString("state");
    if (!code) {
      ElMessage.error("登录错误！");
      router.push("/login");
    } else {
      window.location.href = `${window.location.origin}${window.location.pathname}#/init?code=${code}&state=${state}`;
    }
  }
}
onMounted(() => {
  goTo();
});
</script>

<style lang="scss" scoped>
#init {
  width: 100vw;
  min-height: calc(100vh - 77px);
  display: flex;
  .right-wp {
    flex: 1;
    width: calc(100vw - 150px);
    background-color: var(--theme-bg-color);
  }
  .right-wp.expand {
    width: calc(100vw - 250px);
  }
}
</style>
